import firebase from "firebase"

const firebaseConfig = {
  apiKey: "AIzaSyCLXBRlYzqiPSDLRK-YvSRWkTtB_gVkWjU",
  authDomain: "ulocal-ccb92.firebaseapp.com",
  databaseURL: "https://ulocal-ccb92.firebaseio.com",
  projectId: "ulocal-ccb92",
  storageBucket: "ulocal-ccb92.appspot.com",
  messagingSenderId: "875727742542",
  appId: "1:875727742542:web:767f6b29952cfb90d0851f",
  measurementId: "G-TNVNJ90KDJ"
}

firebase.initializeApp(firebaseConfig)

export const firebaseAuth = firebase.auth()
