import React from "react"
import MUIDataTable from "mui-datatables"
import { useHistory } from "react-router-dom"
import { CircularProgress } from "@material-ui/core"

const API_URL = "https://mobapi.ulocal.co/api/Statistics"

const columns = [
  "ID de l'entreprise",
  "Ajoutée aux favoris",
  "Ajoutée aux circuits",
  "Page ouverte",
  "Marker appuyé"
]

const CompaniesTable = () => {
  const history = useHistory()
  const [tableData, setTableData] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    setLoading(true)

    fetch(API_URL)
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
        const preTableData = []

        data.forEach((statistic) => {
          preTableData.push([
            statistic.company_id,
            statistic.was_added_to_circiuits,
            statistic.was_added_to_favorites,
            statistic.was_marker_tapped,
            statistic.was_page_open
          ])
        })

        setTableData(preTableData)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  const options = {
    selectableRowsHeader: false,
    selectableRows: "none",
    onRowClick: (rowData, rowMeta) => {
      const companyId = rowData[0]
      history.push(`/company/${companyId}`)
    },
    rowsPerPage: 100,
    rowsPerPageOptions: [50, 100, 200, 1000],
    textLabels: {
      body: {
        noMatch: "Désolé, rien a été trouvé",
        toolTip: "Sort",
        columnHeaderTooltip: column => `Sort for ${column.label}`
      },
      pagination: {
        next: "Page Suivante",
        previous: "Page Précedente",
        rowsPerPage: "Lignes par page:",
        displayRows: "de",
      },
      toolbar: {
        search: "Rechercher",
        downloadCsv: "Télécharger CSV",
        print: "Imprimer",
        viewColumns: "Afficher Colonnes",
        filterTable: "Filter Table",
      },
      filter: {
        all: "Tout",
        title: "FILTRES",
        reset: "RÉINITIALIZER",
      },
      viewColumns: {
        title: "Afficher colonnes",
        titleAria: "Afficher/Masquer Colonnes",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Supprimer",
        deleteAria: "Supprimer lignes sélectionnées",
      },
    }
  }
  return (
    <div style={{ margin: 30 }}>
      {loading ? <CircularProgress size={80} /> : (
        <MUIDataTable
          title="Liste d'entreprises"
          data={tableData}
          columns={columns}
          options={options}
        />
      )}
    </div>
  )
}

export default CompaniesTable
