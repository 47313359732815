import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CssBaseline from '@material-ui/core/CssBaseline'

import CompaniesTable from './CompaniesTable'
import { firebaseAuth } from '../../services/firebase'
import SearchBar from './SearchBar'
import AppBar from '../../components/AppBar'
import { CircularProgress, Link } from '@material-ui/core'
import SearchResultsTable from './SearchResultsTable'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  warning: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  table: {
    marginTop: theme.spacing(2),
    width: 850,
  },
  loading: {
    margin: 20
  },
  link: {
    "&:hover": {
      cursor: "pointer"
    }
  }
}))

const HomePage = () => {
  const classes = useStyles()
  const history = useHistory()
  const [searchLoading, setSearchLoading] = React.useState(false)
  const [searchResult, setSearchResult] = React.useState([])

  useEffect(() => {
    firebaseAuth.onAuthStateChanged((user) => {
      if (!user) {
        history.push("/")
      }
    })
  }, [])

  const getTable = () => {
    if (searchResult.length > 0) {
      return (
        <>
          <Link
            className={classes.warning}
            onClick={() => {
              setSearchResult([])
            }}
          >
            <Typography className={classes.link}>
              Revenir à la table principale.
            </Typography>
          </Link>

          <div className={classes.table}>
            <SearchResultsTable searchResult={searchResult} />
          </div>

        </>
      )
    } else {
      return (
        <>
          <Typography variant="body1" className={classes.warning}>
            Ces statistiques ont commencé à être enregistrées le 1er aoüt.
          </Typography>

          <div className={classes.table}>
            <CompaniesTable />
          </div>
        </>
      )
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar />

      <SearchBar
        setSearchLoading={setSearchLoading}
        setSearchResult={setSearchResult}
      />

      {searchLoading ? (
        <div className={classes.loading}>
          <CircularProgress size={80} />
        </div>
      ) : getTable()}
    </div>
  )
}
export default HomePage
