import React from "react"
import {
  Button, Typography, Toolbar, makeStyles, AppBar
} from "@material-ui/core"
import { firebaseAuth } from "../services/firebase"
import { useHistory } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    cursor: "pointer"
  },
}))

const TopAppBar = () => {
  const classes = useStyles()

  const history = useHistory()

  const handleLogout = () => {
    firebaseAuth.signOut()
    history.push("/")
  }

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography
          variant="h6"
          className={classes.title}
          onClick={() => {
            history.push("/home")
          }}
        >
          Ulocal
        </Typography>

        <Button
          onClick={handleLogout}
          color="inherit"
        >
          Se déconnecter
          </Button>
      </Toolbar>
    </AppBar>
  )
}

export default TopAppBar