import React from "react"
import { makeStyles, Typography, CssBaseline, CircularProgress } from "@material-ui/core"

import AppBar from '../../components/AppBar'
import { useParams } from "react-router-dom"
import { useEffect } from "react"

const API_URL = "https://mobapi.ulocal.co/api/Company"

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: "column",
    alignItems: 'center',
  },
  mainWrapper: {
    maxWidth: 900,
    display: 'flex',
    flexDirection: "column",
    alignItems: 'center',
  },
  loading: {
    marginTop: 30
  },
  title: {
    margin: 20,
  },
  contentWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  about: {
    width: 450,
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "column",
    width: 450,
    marginLeft: 20
  }
}))

const CompanyPage = () => {
  const [loading, setLoading] = React.useState(true)
  const [companyData, setCompanyData] = React.useState({})
  const classes = useStyles()
  const params = useParams()

  useEffect(() => {
    fetch(`${API_URL}/${params.id}`)
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        setCompanyData(res)
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
      })
  }, [params.id])

  function getEcoGrades () {
    const ecoGrades = []

    companyData.ecoGrades.forEach((ecoGrade) => {
      ecoGrades.push(ecoGrade.idText)
    })

    return ecoGrades?.join(", ")
  }

  function getCategories () {
    const categories = []

    companyData.categories.forEach((category) => {
      categories.push(category.idText)
    })

    return categories.join(", ")
  }

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar />

      {loading ?
        <div className={classes.loading}>
          <CircularProgress size={90} />
        </div>
        : (
          <div className={classes.mainWrapper}>
            <div className={classes.title}>
              <Typography variant="h4">
                {companyData.details.title}
              </Typography>
            </div>

            <div className={classes.contentWrapper}>
              <div className={classes.about}>
                <Typography variant="h6">
                  À propos
                </Typography>

                <Typography variant="body2">
                  {companyData.details.description}
                </Typography>
              </div>

              <div className={classes.infoWrapper}>
                <strong>Addresse: </strong> {companyData.address.street} {companyData.address.city} {companyData.address.state} {companyData.address.country}
                <strong>Language: </strong> {companyData.language}
                <strong>Coordonnées: </strong> {companyData.lat}, {companyData.lng}
                {companyData?.ecoGrades && (
                  <>
                    <strong>Eco Grades: </strong> {getEcoGrades()}
                  </>
                )}
                {companyData?.categories && (
                  <>
                    <strong>Categories: </strong> {getCategories()}
                  </>
                )}

                <strong>Communications</strong>
                {companyData?.communications?.map((communication) => {
                  return (
                    <span>
                      <strong>{communication.communicationType} </strong>
                      {communication.communicationValue}
                    </span>
                  )
                })}
              </div>
            </div>
          </div>
        )}
    </div>
  )
}

export default CompanyPage
