import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'

const API_URL = "https://mobapi.ulocal.co/api/SearchCompanies"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "30px",
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}))

const SearchBar = ({ setSearchLoading, setSearchResult }) => {
  const classes = useStyles()
  const [searchLabel, setSearchLabel] = React.useState("")

  const handleSubmit = (e) => {
    e.preventDefault()
    setSearchLoading(true)
    const endpoint = `${API_URL}/${searchLabel}`

    fetch(endpoint)
      .then((res) => res.json())
      .then((res) => {
        setSearchResult(res)
        setSearchLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setSearchLoading(false)
      })
  }

  return (
    <Paper
      component="form"
      className={classes.root}
      onSubmit={handleSubmit}
    >
      <InputBase
        onChange={(e) => setSearchLabel(e.target.value)}
        className={classes.input}
        placeholder="Rechercher une entreprise"
        inputProps={{ 'aria-label': 'search google maps' }}
      />

      <IconButton
        type="submit"
        className={classes.iconButton}
        aria-label="search"
        onClick={handleSubmit}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  )
}

export default SearchBar
