import React from "react"

import {
  Switch, Route, BrowserRouter
} from "react-router-dom"

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import HomePage from "./pages/HomePage"
import LoginPage from "./pages/LoginPage"
import CompanyPage from "./pages/CompanyPage";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#71BB20",
      dark: '#488A00',
      light: "#F4F8F0"
    },
  },
  typography: {
    fontFamily: [
      "Montserrat",
      "Roboto",
      "Arial"
    ].join(",")
  }
});

const Router = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <LoginPage />
          </Route>

          <Route path="/home">
            <HomePage />
          </Route>

          <Route path="/company/:id">
            <CompanyPage />
          </Route>
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default Router
