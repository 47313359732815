import React, { useState } from 'react'

import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import { useHistory } from "react-router-dom"

import ulocalLogo from "../../images/logo.png"
import { useEffect } from 'react'
import { firebaseAuth } from '../../services/firebase'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    marginBottom: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "white"
  },
}))

const LoginPage = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  useEffect(() => {
    firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        history.push("/home")
      }
    })
  }, [history])

  const handleLogin = (e) => {
    setLoading(true)
    e.preventDefault()

    const emailCleaned = email.toLowerCase().trim()

    firebaseAuth
      .signInWithEmailAndPassword(emailCleaned, password)
      .then((res) => {
        setLoading(false)
        console.log(res, "res")
        history.push("/home")
      })
      .catch((e) => {
        console.log(e, "e")
        setLoading(false)

        if (e.code === "auth/invalid-email") {
          alert("Le courriel que vous avez mis n'est pas correct. Veuillez le corriger.")
        } else if (e.code === "auth/user-not-found") {
          alert("Il n'y a aucun utilisateur avec ce courriel. Veuillez essayer autre courriel.")
        } else if (e.code === "auth/wrong-password") {
          alert("Le mot de passe n'est pas correct. Veuillez ressayer.")
        } else {
          alert("Le courriel ou mot de passe que vous avez mis n'est pas correct. Veuillez ressayer.")
        }
      })
  }

  const classes = useStyles()

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <div className={classes.paper}>
        <div className={classes.logo}>
          <img height={100} src={ulocalLogo} alt="Logo" />
        </div>

        <Typography component="h1" variant="h5">
          Se connecter
        </Typography>

        <form
          onSubmit={handleLogin}
          className={classes.form}
          noValidate
        >
          <TextField
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Votre courriel"
            name="email"
            autoComplete="email"
            autoFocus
          />

          <TextField
            onChange={(e) => setPassword(e.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Mot de passe"
            type="password"
            id="password"
            autoComplete="current-password"
          />

          {loading ? <CircularProgress /> : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Se connecter
            </Button>
          )}
        </form>
      </div>
    </Container>
  )
}

export default LoginPage
