import React from "react"
import MUIDataTable from "mui-datatables"
import { useHistory } from "react-router-dom"
import { CircularProgress } from "@material-ui/core"

const API_URL = "https://mobapi.ulocal.co/api/Statistics"

const columns = [
  "ID",
  "Title"
]

const CompaniesTable = ({ searchResult }) => {
  const history = useHistory()
  const [tableData, setTableData] = React.useState([])

  const populateTable = () => {
    const preTableData = []

    searchResult.forEach((company) => {
      preTableData.push([
        company.id,
        company.title,
      ])
    })

    setTableData(preTableData)
  }

  React.useEffect(() => {
    populateTable()
  }, [])

  const options = {
    selectableRowsHeader: false,
    selectableRows: "none",
    onRowClick: (rowData, rowMeta) => {
      const companyId = rowData[0]
      history.push(`/company/${companyId}`)
    },
    rowsPerPage: 100,
    rowsPerPageOptions: [50, 100, 200, 1000],
    textLabels: {
      body: {
        noMatch: "Désolé, rien a été trouvé",
        toolTip: "Sort",
        columnHeaderTooltip: column => `Sort for ${column.label}`
      },
      pagination: {
        next: "Page Suivante",
        previous: "Page Précedente",
        rowsPerPage: "Lignes par page:",
        displayRows: "de",
      },
      toolbar: {
        search: "Rechercher",
        downloadCsv: "Télécharger CSV",
        print: "Imprimer",
        viewColumns: "Afficher Colonnes",
        filterTable: "Filter Table",
      },
      filter: {
        all: "Tout",
        title: "FILTRES",
        reset: "RÉINITIALIZER",
      },
      viewColumns: {
        title: "Afficher colonnes",
        titleAria: "Afficher/Masquer Colonnes",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Supprimer",
        deleteAria: "Supprimer lignes sélectionnées",
      },
    }
  }
  return (
    <div style={{ margin: 30 }}>
      <MUIDataTable
        title="Résultat"
        data={tableData}
        columns={columns}
        options={options}
      />
    </div>
  )
}

export default CompaniesTable
